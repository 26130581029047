import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Switch} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
  margin: theme.spacing(2)
  },
}));

export default function CMS() {
  const classes = useStyles();
  const history = useHistory();

  const {
    cmsConfig, setCmsConfig,
    imsConfig,
  } = useSharedState();

  function cmsChangeHandler() {
    if(cmsConfig.enabled) {
      setCmsConfig({
        ...cmsConfig,
        "enabled": !cmsConfig.enabled,
        "itemScan":false,
        "stationCamera":false
      })
    } else {
      setCmsConfig({
        ...cmsConfig,
        "enabled": !cmsConfig.enabled
      });
  }
  }

  function itemScanHandler() {
    setCmsConfig({
      ...cmsConfig,
      "itemScan": !cmsConfig.itemScan
    });
  }

  function cmsCameraHandler() {
    setCmsConfig({
      ...cmsConfig,
      "stationCamera": !cmsConfig.stationCamera
    });
  }

    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Cooking Management" subheader={<Switch checked={cmsConfig.enabled} onChange={cmsChangeHandler} size="large" color="primary" name="oms"/>}/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  {/* <Typography>Printing Options: </Typography> */}
                  
                  <Grid style={{marginTop: 10}} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Configuration: </Typography>
                  </Grid>
                    <Grid item xs={12}>
                    <FormControl disabled={!cmsConfig.enabled} component="fieldset" className={classes.formControl}>
                        {/* <FormLabel component="legend">Printing Options</FormLabel> */}
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox disabled={!cmsConfig.enabled} checked={cmsConfig.itemScan} onChange={itemScanHandler} color="primary" name="item-scan" />}
                            label="Enable Item Scan"
                        />
                        {/* <FormControlLabel
                            control={<Checkbox disabled={!cmsConfig.enabled || !imsConfig.enabled} checked={imsConfig.enabled?cmsConfig.stationCamera:false} onChange={cmsCameraHandler} color="primary" name="station-camera" />}
                            label="Enable Cooking Station Camera *"
                        />
                        <FormHelperText>*  Requires Inventory Management</FormHelperText> */}
                        <FormControlLabel
                            control={<Checkbox disabled={!cmsConfig.enabled } checked={cmsConfig.stationCamera} onChange={cmsCameraHandler} color="primary" name="station-camera" />}
                            label="Enable Cooking Station Camera"
                        />
                        </FormGroup>
                    </FormControl>
                  </Grid>
                  </Grid>
                 
                </div>
              </Container>
            </CardContent>
        </Card>
      </div>
    );
};
