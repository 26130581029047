import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Divider} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';
import { useDropzone } from 'react-dropzone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
    minWidth: 120,
  },
}));

export default function TradeRegistrations() {
  const classes = useStyles();
  const history = useHistory();

  const [gstError, setGstError] = useState('');

  const { 
    gstNumber, setGstNumber,
    fssaiNumber, setFssaiNumber,
    companyCert, setCompanyCert, companyCertName, setCompanyCertName, fssaiCert, setFssaiCert,
    fssaiCertName, setFssaiCertName, businessPan, setBusinessPan, businessPanName, setBusinessPanName,
    identityProof, setIdentityProof, identityProofName, setIdentityProofName, cancelledCheque, setCancelledCheque,
    cancelledChequeName, setCancelledChequeName, fileUrl
  } = useSharedState();

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

  async function fileHandler(file, name) {
    if(file !== undefined) {
      //let fileb64 = await toBase64(file);
      if(name === "CompanyCert") {
        setCompanyCert(file);
        setCompanyCertName(file.name);
      } else if(name === "FssaiCert") {
        setFssaiCert(file);
        setFssaiCertName(file.name);
      } else if(name === 'BusinessPan') {
        setBusinessPan(file); 
        setBusinessPanName(file.name);
      } else if(name === 'IdentityProof') {
        setIdentityProof(file);
        setIdentityProofName(file.name);
      } else if(name === 'CancelledCheque') {
        setCancelledCheque(file);
        setCancelledChequeName(file.name);
      }
    }
  }

  function checkGst(value) {
    setGstNumber(value);
    if (/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(value)) {
      setGstError('');
    } else {
      setGstError('Please enter valid GSTIN number');
    }
  }

  function FileInput(props) {
    const { getRootProps, getInputProps, open, acceptedFile } = useDropzone({
      accept: '.jpg, .pdf, .jpeg, .png',
      maxSize: 2000000,
      maxFiles: 1,
      onDrop: files=>fileHandler(files[0],props.fileName),
    });
    return (
      <>
        <input {...getInputProps()}/>
        <Button variant="contained" style={{ float: 'right'}} color="primary" onClick={open}>Select</Button>
      </>
    );
  }

    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Trade Registrations"/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="GST Number"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={gstNumber}
                    onChange={e=>checkGst(e.target.value)}
                  />
                  <Typography style={{textAlign:'center',color:'red'}}>{gstError}</Typography>
                  <Grid style={{marginTop: 15,marginBottom: 10,backgroundColor: '#ebe6d8'}} container spacing={2}>
                      <Grid item xs={6}>
                          <Typography>Company Certificate</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FileInput fileName="CompanyCert"/>
                      </Grid>
                       <Grid item xs={8}>
                          {companyCertName}
                       </Grid>
                     <Grid item xs={4}>
                       {fileUrl.companyCert !== null && fileUrl.companyCert !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="FSSAI Number"
                    id="password"
                    autoComplete="current-password"
                    value={fssaiNumber}
                    onChange={e=>setFssaiNumber(e.target.value)}
                  />
                  <Grid style={{marginTop: 15,marginBottom: 10,backgroundColor: '#ebe6d8'}} container spacing={2}>
                      <Grid item xs={6}>
                          <Typography>FSSAI Certificate *</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FileInput fileName="FssaiCert"/>
                      </Grid>
                       <Grid item xs={8}>
                          {fssaiCertName}
                       </Grid>
                     <Grid item xs={4}>
                       {fileUrl.fssaiCert !== null && fileUrl.fssaiCert !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                  {/* <Divider style={{margin:20}}/> */}
                  <Grid style={{marginTop: 15,marginBottom: 10,backgroundColor: '#ebe6d8'}} container spacing={2}>
                      <Grid item xs={6}>
                          <Typography>Business PAN *</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FileInput fileName="BusinessPan"/>
                      </Grid>
                       <Grid item xs={8}>
                          {businessPanName}
                       </Grid>
                     <Grid item xs={4}>
                       {fileUrl.businessPan !== null && fileUrl.businessPan !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                  <Grid style={{marginTop: 15,marginBottom: 10,backgroundColor: '#ebe6d8'}} container spacing={2}>
                      <Grid item xs={6}>
                          <Typography>Identity Proof *</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FileInput fileName="IdentityProof"/>
                      </Grid>
                       <Grid item xs={8}>
                          {identityProofName}
                       </Grid>
                     <Grid item xs={4}>
                       {fileUrl.identityProof !== null && fileUrl.identityProof !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                  <Grid style={{marginTop: 15,marginBottom: 10,backgroundColor: '#ebe6d8'}} container spacing={2}>
                      <Grid item xs={6}>
                          <Typography>Cancelled Cheque *</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FileInput fileName="CancelledCheque"/>
                      </Grid>
                       <Grid item xs={8}>
                          {cancelledChequeName}
                       </Grid>
                     <Grid item xs={4}>
                       {fileUrl.cancelledCheque !== null && fileUrl.cancelledCheque !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                </div>
              </Container>
            </CardContent>
        </Card>
      </div>
    );
};
