import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Switch} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
  margin: theme.spacing(2)
  },
}));

export default function OMS() {
  const classes = useStyles();
  const history = useHistory();

  const {
    omsConfig, setOmsConfig
  } = useSharedState();

    const handleChange = () => {
        setOmsConfig({
            ...omsConfig,
            "printing" : {
                "ipEnabled":!omsConfig.printing.ipEnabled
            }
        })
    };

    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Order Management" subheader={<Switch checked={true} size="large" disabled color="primary" name="oms"/>}/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  {/* <Typography>Printing Options: </Typography> */}
                  
                  {/* <Grid style={{marginTop: 10}} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Printing Options: </Typography>
                  </Grid>
                    <Grid item xs={12}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={handleChange} checked={omsConfig.printing.ipEnabled} color="primary" name="Ip" />}
                            label="Enable KOT/Bill Printing"
                        />
                        </FormGroup>
                    </FormControl>
                  </Grid>
                  
            
                  </Grid> */}
                 
                </div>
              </Container>
            </CardContent>
        </Card>
      </div>
    );
};
