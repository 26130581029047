import React from "react";
import { Route, Redirect } from "react-router-dom";
import Approved from "../pages/Approved";
import Wait from "../pages/Wait";
import {useSharedState} from "./sharedState";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  const {isLoggedIn,isCompleted, isApproved} = useSharedState();
  return (
    <Route
      {...rest}
      render={props => {
        if (isApproved) {
          return <Approved {...props}/>
        }
        else if (isCompleted) {
          return <Wait {...props}/>
        }
        else if (isLoggedIn) {
          return <Component {...props}/>;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
