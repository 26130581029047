import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Switch, Snackbar} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../helpers/sharedState';
import MuiAlert  from '@material-ui/lab/Alert';
import {api} from '../helpers/urls';
import AlertDialog from '../components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
  margin: theme.spacing(2)
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Verification(props) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [loading, setLoading]= React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const { userToken, setDialogOpen, isCompleted, setIsCompleted, userEmail,
      companyName, headOfficeAddress, pincode, selectedCity, selectedState, 
      managerName, managerPhone, businessDisplayName, gstNumber, fssaiNumber,
      brandName, brandDescription, fileUrl, deliveryEnabled, deliveryConfig, paymentConfig
  } = useSharedState();

const snackbarAlert = (message) => {
    setOpen(true);
    setMessage(message);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function validateForm() {
    let err = [];
    if(companyName === '') err.push('Please enter valid company name');
    if(headOfficeAddress === '') err.push('Please enter head office address');
    if(businessDisplayName === '') err.push('Please enter valid business display name');
    if(pincode === '' || selectedCity === '' || selectedState === '') err.push('Please enter valid pincode');
    if(managerName === '') err.push('Please enter valid store manager name');
    if(managerPhone === '') err.push('Please provide store manager contact number');
    if(managerPhone.length !== 10) err.push('Manager phone number should be of 10 digits');
    if(gstNumber !== '' && !(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gstNumber))) err.push('Please enter a valid GST Number')
    if(fssaiNumber === '') err.push('Please enter valid FSSAI number');
    if(brandName === '') err.push('Please enter valid brand name');
    if(deliveryEnabled === true) {
      if(deliveryConfig.ownDelivery === false && deliveryConfig.dunzo === false) {
        err.push('Please select at least one delivery option')
      }
    }
    if(paymentConfig.cod === false && paymentConfig.digital === false) {
      err.push('Please select at least one payment option');
    }
    if(brandDescription === '') err.push('Please enter valid brand description');
    Object.keys(fileUrl).map(elem=>{
      if(fileUrl[elem] === null || fileUrl[elem] === '') {
        err.push(`Please upload ${elem}`);
      }
    })
    setErrors(err);
    if(err.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  async function clickHandler() {
    // handleClickOpen();
    const errorExists = validateForm();
    if(errorExists) {
      return;
    }
    setLoading(true);
    if (props.saveConfig(true)) {
      try {
        const response = await fetch(api.submitDetails+`?Email=${userEmail}`,{
          method: 'GET',
          headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',
            'Token':userToken,
          }
        },5000); 
        const res = await response.json();
        if (res.errorMessage) {
          snackbarAlert(res.errorMessage);
        } else {
          setIsCompleted(true);
        }
        setLoading(false);
  
      } catch(err) {
        console.log(err);
        snackbarAlert('Some error occurred, Please try again');
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    }
    

  const handleClickOpen = () => {
    setDialogOpen(true);
  };


    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Verification" />
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  
                  <Grid style={{marginTop: 10}}  container spacing={2}>
                    <Grid justify="center" style={{textAlign:'center'}} item xs={12}>
                    <Typography variant="h6">Please note that it may take upto 24 hours for your application to get approved. Please check your email for further communication.</Typography>
                  </Grid>
                    <Grid justify="center" style={{textAlign:'center'}} item xs={12}>
                    <Button disabled={loading} onClick={clickHandler} variant='contained' color='primary' fullWidth >{loading?'Submitting':'Submit Details'}</Button>
                  </Grid>
            
                  </Grid>
                 
                </div>
              </Container>
            </CardContent>
        </Card>
       {errors.length > 0 && <Card style={{ padding: "1%", marginTop: 10 }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Errors" />
          <CardContent>
              <Container maxWidth="xs">
                {/* <div className={classes.form} noValidate>
                  
                  <Grid style={{marginTop: 10}}  container spacing={2}>
                    <Grid justify="center" style={{textAlign:'center'}} item xs={12}>
                    <Typography variant="h6">Please note that it may take upto 24 hours for your application to get approved. Please check your email for further communication.</Typography>
                  </Grid>
                    <Grid justify="center" style={{textAlign:'center'}} item xs={12}>
                    <Button disabled={loading} onClick={clickHandler} variant='contained' color='primary' fullWidth >{loading?'Submitting':'Submit Details'}</Button>
                  </Grid>
            
                  </Grid>
                 
                </div> */}
                <ul>
                  {errors.map(elem=>{
                    return (
                      <li>
                        <Typography style={{color:'red'}}>{elem}</Typography>
                      </li>
                    )
                  })}                  
                </ul>
              </Container>
            </CardContent>
        </Card>}
       <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          {message}
        </Alert>
      </Snackbar>
      {/* <AlertDialog/> */}
      </div>
    );
};
