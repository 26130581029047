import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert  from '@material-ui/lab/Alert';
import { useSharedState } from '../helpers/sharedState';

export default function ShowSnackbar(props) {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    //   const [snackbarOpen, setOpen] = useState(false);
    const { dialogOpen, setDialogOpen} = useSharedState();

      const snackbarAlert = (message) => {
        setDialogOpen(true);
        // setMessage(message);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setDialogOpen(false);
      };

      return (
        <Snackbar open={dialogOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          {props.message}
        </Alert>
      </Snackbar>
      )
}