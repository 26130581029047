import React, { useCallback, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Account from './pages/Account';
import { SharedContext } from './helpers/sharedState';
import { ProtectedRoute } from './helpers/ProtectedRoute';
import Wait from './pages/Wait';
import Approved from './pages/Approved';
import Landing from './pages/Landing/Landing';

function App() {

  // Auth 
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userToken, setUserToken] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isApproved, setIsApproved] = React.useState(false);
  const [consoleUrl, setConsoleUrl] = React.useState('');


  const signIn = useCallback((uid, email, comp, approved, url) => {
    setUserToken(uid);
    setUserEmail(email);
    setIsLoggedIn(true);
    setIsCompleted(comp);
    setIsApproved(approved);
    setConsoleUrl(url);
  });

  const signOut = useCallback(() => {
    setIsLoggedIn(false);
    setUserToken(null);
    setUserEmail(null);
    setIsCompleted(false);
    setIsApproved(false);
    setConsoleUrl('');
  });

  // Restaurant Details 
  const [companyName, setCompanyName] = useState("");
  const [headOfficeAddress, setHeadOfficeAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [pincode, setPincode] = useState('');
  const [managerName, setManagerName] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const [businessDisplayName, setBusinessDisplayName] = useState('');

  // Trade Registration
  const [gstNumber, setGstNumber] = useState("");
  const [fssaiNumber, setFssaiNumber] = useState("");
  const [companyCert, setCompanyCert] = useState(null);
  const [companyCertName, setCompanyCertName] = useState("");
  const [fssaiCert, setFssaiCert] = useState(null);
  const [fssaiCertName, setFssaiCertName] = useState("");
  const [businessPan, setBusinessPan] = useState(null);
  const [businessPanName, setBusinessPanName] = useState('');
  const [identityProof, setIdentityProof] = useState(null);
  const [identityProofName, setIdentityProofName] = useState('');
  const [cancelledCheque, setCancelledCheque] = useState(null);
  const [cancelledChequeName, setCancelledChequeName] = useState('');

  // Brand Details
  const [brandName, setBrandName] = React.useState("");
  const [brandDescription, setBrandDescription] = React.useState("");
  const [selectedTheme, setSelectedTheme] = React.useState({
    "primary": "#e62910",
    "secondary": "#f2de2c"
  });
  const [brandLogo, setBrandLogo] = React.useState(null);
  const [brandLogoFilename, setBrandLogoFilename] = React.useState("");
  const [trademarkCert, setTrademarkCert] = React.useState(null);
  const [tmCertFilename, setTmFilename] = React.useState("");

  // Files
  const [fileUrl, setFileUrl] = useState({
    'companyCert': '',
    'fssaiCert': '',
    'businessPan': '',
    'identityProof': '',
    'cancelledCheque': '',
    'trademarkCert': '',
    'brandLogo': ''
  });

  // OMS
  const [omsConfig, setOmsConfig] = useState({
    "enabled": true,
    "printing": {
      "ipEnabled": false
    },
  });

  // QMS
  const [qmsConfig, setQmsConfig] = useState({
    "enabled": false,
    "qualityCheck": {
      "image": true,
      "weight": false,
      "temp": false
    },
    "printing": {
      "ipEnabled": false
    }
  })

  // IMS
  const [imsConfig, setImsConfig] = useState({
    "enabled": false,
    "qualityCheck": {
      "image": true,
      "weight": false,
      "temp": false
    },
    "printing": {
      "ipEnabled": false
    }
  })

  // CMS
  const [cmsConfig, setCmsConfig] = useState({
    "enabled": false,
    "itemScan": false,
    "stationCamera": false
  });

  // Delivery & Payment
  const [deliveryEnabled, setDeliveryEnabled] = React.useState(false);
  const [paymentEnabled, setPaymentEnabled] = React.useState(true);
  const [paymentConfig, setPaymentConfig] = React.useState({ "cod": false, "digital": false });
  const [deliveryConfig, setDeliveryConfig] = React.useState({ "ownDelivery": false, "dunzo": false });




  const sharedState = {
    userEmail, userToken,
    isLoggedIn, signIn, signOut,
    companyName, setCompanyName, headOfficeAddress, setHeadOfficeAddress, selectedCity, setSelectedCity, pincode, setPincode,
    selectedState, setSelectedState, managerName, setManagerName, managerPhone, setManagerPhone,
    gstNumber, setGstNumber, fssaiNumber, setFssaiNumber,
    brandName, setBrandName, brandDescription, setBrandDescription, selectedTheme, setSelectedTheme,
    omsConfig, setOmsConfig,
    qmsConfig, setQmsConfig,
    imsConfig, setImsConfig,
    cmsConfig, setCmsConfig,
    deliveryEnabled, setDeliveryEnabled, paymentEnabled, setPaymentEnabled,
    paymentConfig, setPaymentConfig, deliveryConfig, setDeliveryConfig,
    companyCert, setCompanyCert, companyCertName, setCompanyCertName, fssaiCert, setFssaiCert,
    fssaiCertName, setFssaiCertName, businessPan, setBusinessPan, businessPanName, setBusinessPanName,
    identityProof, setIdentityProof, identityProofName, setIdentityProofName, cancelledCheque, setCancelledCheque,
    cancelledChequeName, setCancelledChequeName,
    brandLogo, setBrandLogo, brandLogoFilename, setBrandLogoFilename, trademarkCert, setTrademarkCert,
    tmCertFilename, setTmFilename, fileUrl, setFileUrl,
    isCompleted, setIsCompleted, dialogOpen, setDialogOpen, businessDisplayName, setBusinessDisplayName, isApproved, setIsApproved, consoleUrl, setConsoleUrl
  };

  return (
    <SharedContext.Provider value={sharedState}>
      <BrowserRouter>
        <Switch>
          <Route path='/' exact>
            <Landing />
          </Route>
          {/* <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/" exact>
            <Register />
          </Route> */}
          <ProtectedRoute path="/redirect" exact component={Approved} />
          <ProtectedRoute path="/review" exact component={Wait} />
          <ProtectedRoute path="/account" exact component={Account} />
        </Switch>
      </BrowserRouter>
    </SharedContext.Provider>
  );
}

export default App;
