import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Switch} from '@material-ui/core';
import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
  margin: theme.spacing(2)
  },
}));

export default function DeliveryPayment() {
  const classes = useStyles();
  const history = useHistory();

  const {
    deliveryEnabled, setDeliveryEnabled,
    paymentEnabled, setPaymentEnabled,
    paymentConfig, setPaymentConfig,
    deliveryConfig, setDeliveryConfig,
  } = useSharedState();

  function formSubmitHandler() {
      history.push('/account'); 
  };

  function deliveryToggleHandler() {
    if (deliveryEnabled) {
      setDeliveryConfig({
        'ownDelivery':false,
        'dunzo':false
      });
      setPaymentConfig({
        ...paymentConfig,
        'cod':false
      })
    }
    setDeliveryEnabled(!deliveryEnabled)
  }

  function paymentToggleHandler() {
    if (paymentEnabled) {
      setPaymentConfig({
        'cod':false,
        'digital':false
      })
    }
    setPaymentEnabled(!paymentEnabled)
  }
    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Delivery" subheader={<Switch checked={deliveryEnabled} onChange={deliveryToggleHandler} size="large" color="primary" name="oms"/>}/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  {/* <Typography>Printing Options: </Typography> */}
                  
                  <Grid style={{marginTop: 10}} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Configuration: </Typography>
                  </Grid>
                    <Grid item xs={12}>
                    <FormControl disabled={!deliveryEnabled} component="fieldset" className={classes.formControl}>
                        {/* <FormLabel component="legend">Printing Options</FormLabel> */}
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={deliveryConfig.ownDelivery} onChange={()=>{setDeliveryConfig({...deliveryConfig,ownDelivery:!deliveryConfig.ownDelivery}); setPaymentConfig({...paymentConfig,cod:false});}} color="primary" name="Ip" />}
                            label="Delivery with Rider Management"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={true} checked={deliveryConfig.dunzo} onChange={()=>setDeliveryConfig({...deliveryConfig,dunzo:!deliveryConfig.dunzo})} color="primary" name="Ip" />}
                            label="Delivery by Dunzo *"
                        />
                        
                        </FormGroup>
                        <FormHelperText>* Coming Soon</FormHelperText>
                    </FormControl>
                  </Grid>
                 
                  
            
                  </Grid>
                 
                </div>
              </Container>
            </CardContent>
        </Card>
        <Card style={{ marginTop:20, padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Payments" subheader={<Switch checked={paymentEnabled} onChange={paymentToggleHandler} size="large" color="primary" name="oms"/>}/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  {/* <Typography>Printing Options: </Typography> */}
                  
                  <Grid style={{marginTop: 10}} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Configuration: </Typography>
                  </Grid>
                    <Grid item xs={12}>
                    <FormControl disabled={!paymentEnabled} component="fieldset" className={classes.formControl}>
                        {/* <FormLabel component="legend">Printing Options</FormLabel> */}
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox disabled={!deliveryConfig.ownDelivery || !paymentEnabled} checked={paymentConfig.cod} onClick={()=>setPaymentConfig({...paymentConfig,cod:!paymentConfig.cod})} color="primary" name="Ip" />}
                            label="Cash on Delivery *"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={paymentConfig.digital} onClick={()=>setPaymentConfig({...paymentConfig,digital:!paymentConfig.digital})} color="primary" name="Ip" />}
                            label="Digital Payments"
                        />
                        </FormGroup>
                        <FormHelperText>* CoD only available with own delivery</FormHelperText>
                    </FormControl>
                  </Grid>
                  
                  
            
                  </Grid>
                 
                </div>
              </Container>
            </CardContent>
        </Card>
      </div>
    );
};
