import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem} from '@material-ui/core';
import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';
import { useDropzone } from 'react-dropzone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
  minWidth: 250
  },
}));

export default function BrandDetails() {
  const classes = useStyles();
  const history = useHistory();

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({ noClick: true, noKeyboard: true, accept: '.jpg, .pdf, .jpeg, .png' });

  const themes = ["Red","Blue","Green","Purple"];

  const {
    brandName, setBrandName,
    brandDescription, setBrandDescription,
    selectedTheme, setSelectedTheme,
    brandLogo, setBrandLogo,brandLogoFilename, setBrandLogoFilename,trademarkCert, setTrademarkCert,
    tmCertFilename, setTmFilename, fileUrl
  } = useSharedState();

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

  async function fileHandler(file, name) {
    if(file !== undefined) {
      if(name === "BrandLogo") {
        setBrandLogo(file);
        setBrandLogoFilename(file.name);
      } else if(name == "TrademarkCert") {
        setTrademarkCert(file);
        setTmFilename(file.name);
      }
    }
  }

  function FileInput(props) {
    const { getRootProps, getInputProps, open, acceptedFile } = useDropzone({
      accept: '.jpg, .pdf, .jpeg, .png',
      maxSize: 2000000,
      maxFiles: 1,
      onDrop: files=>fileHandler(files[0],props.fileName),
    });
    return (
      <>
        <input {...getInputProps()}/>
        <Button variant="contained" style={{ float: 'right'}} color="primary" onClick={open}>Select</Button>
      </>
    );
  }

  function themeHandler(e,t) {
    if(t==="primary") {
      setSelectedTheme({
        ...selectedTheme,
        "primary":e.target.value
      });      
    } else if(t==='secondary') {
      setSelectedTheme({
        ...selectedTheme,
        "secondary":e.target.value
      });      
    }
  }

    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Brand Details"/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Brand Name"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={brandName}
                    onChange={e=>setBrandName(e.target.value)}
                  />
                  <Grid style={{marginTop: 15,marginBottom: 10,backgroundColor: '#ebe6d8'}} container spacing={2}>
                      <Grid item xs={6}>
                          <Typography>Trademark Certificate *</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FileInput fileName="TrademarkCert"/>
                      </Grid>
                       <Grid item xs={8}>
                          {tmCertFilename}
                       </Grid>
                     <Grid item xs={4}>
                       {fileUrl.trademarkCert !== null && fileUrl.trademarkCert !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    multiline
                    rows={4}
                    fullWidth
                    name="password"
                    label="Brand Tagline"
                    id="password"
                    autoComplete="current-password"
                    value={brandDescription}
                    onChange={e=>setBrandDescription(e.target.value)}
                  />
                  <Grid style={{marginTop: 10, marginBottom: 10}} container spacing={2}>
                    <Grid item xs={8}>
                      <Typography>Primary Theme Color *</Typography>
                  </Grid>
                    <Grid item xs={4}>
                      <input value={selectedTheme.primary} onChange={e=>themeHandler(e,'primary')} style={{float:'right'}} type="color"/>
                  </Grid>
                    <Grid item xs={8}>
                      <Typography>Secondary Theme Color *</Typography>
                  </Grid>
                    <Grid item xs={4}>
                      <input value={selectedTheme.secondary} onChange={e=>themeHandler(e,'secondary')} style={{float:'right'}} type="color"/>
                  </Grid>
                  </Grid>
                  <Grid style={{marginTop: 15,marginBottom: 10, backgroundColor: '#ebe6d8'}} container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>Brand Logo *</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FileInput fileName="BrandLogo"/>
                    </Grid>
                     <Grid item xs={8}>
                        {brandLogoFilename}
                     </Grid>
                     <Grid item xs={4}>
                       {fileUrl.brandLogo !== null && fileUrl.brandLogo !== "" && <CheckCircleIcon style={{float:'right', color:'green'}}/>}
                     </Grid>
                  </Grid>
                 
                </div>
              </Container>
            </CardContent>
        </Card>
      </div>
    );
};
