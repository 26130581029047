import '../App.css';
import React, { useEffect, useState } from 'react';
import Check from '../images/check.png';
import { CheckCircle } from '@material-ui/icons';
import { useSharedState } from '../helpers/sharedState';

function Approved() {

	const [timer,setTimer] = useState(0);
	const [intervalId, setIntervalId] = useState('');
	const [completed, setCompleted] = useState(false);
	const [timeLeft, setTimeLeft] = useState(5);


	const { consoleUrl } = useSharedState();

	function initTimer() {
		if(consoleUrl !== '' && consoleUrl !== undefined) {
			setTimeout(()=>{
				window.location.replace(consoleUrl);
			},5000);
		}
	}

	useEffect(() => {
		initTimer();
		if(timeLeft===0){
		   console.log("TIME LEFT IS 0");
		   setTimeLeft(null)
		}
	
		// exit early when we reach 0
		if (!timeLeft) return;
	
		// save intervalId to clear the interval when the
		// component re-renders
		const intervalId = setInterval(() => {
	
		  setTimeLeft(timeLeft - 1);
		}, 1000);
	
		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(intervalId);
		// add timeLeft as a dependency to re-rerun the effect
		// when we update it
	  }, [timeLeft]);

    return (
        <div className="App" style={{padding:10,marginTop:20}}>
            <h2 style={{marginTop:40}}>Your Application is Approved! Redirecting you to your personal console in {timeLeft} seconds ...</h2>
	    <h5>If you are not redirected automatically, Please click <a href={consoleUrl}>here</a></h5>
        </div>
    )
}

export default Approved;