import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, IconButton, Snackbar} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';
import { Search } from '@material-ui/icons';
import MuiAlert  from '@material-ui/lab/Alert';
import './disableArrows.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
    minWidth: 120,
  },
}));

export default function RestaurantDetails() {
  const classes = useStyles();
  const history = useHistory();

  const {
    companyName, setCompanyName,
    headOfficeAddress, setHeadOfficeAddress,
    selectedCity, setSelectedCity,
    selectedState, setSelectedState,
    managerName, setManagerName,
    managerPhone, setManagerPhone,
    pincode, setPincode, businessDisplayName, setBusinessDisplayName
  } = useSharedState();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);

const snackbarAlert = (message) => {
    setOpen(true);
    setMessage(message);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const locations = {
    "states" : [
      "Delhi",
      "Gujarat",
      "Karnataka",
      "Maharashtra",
      "Uttar Pradesh",
    ],
    "cities" : [
      "Bangalore",
      "Delhi",
      "Mumbai",
    ]
  }

  async function pincodeHandler(value) {
    setPincode(value);
    console.log(value.length);
    if (value.length === 6) {
    try {
      const response = await fetch('https://api.postalpincode.in/pincode/'+value,{
        method: 'GET',
      },10000);
      const resp = await response.json();
      const res = resp[0];
      if (res.Status !== undefined) {
        if(res.Status === "Success") {
          let postOfficeList = [...res.PostOffice];
          setSelectedCity(postOfficeList[0]['Region']);
          setSelectedState(postOfficeList[0]['State']);
        } else if(res.Status === "Error") {
          snackbarAlert("Please enter a valid Pincode");
        }

      } else {
        snackbarAlert("Could not get City & State. Make sure you have entered the correct Pincode.");
      }
    } catch(err) {
      console.log(err);
      snackbarAlert("Could not get City & State. Make sure you have entered the correct Pincode.")
    }
  } else {
    setSelectedCity('');
    setSelectedState('');
  }
  }
  
  function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Restaurant Details"/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Company Name"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={companyName}
                    onChange={e=>setCompanyName(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Business Display Name"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={businessDisplayName}
                    onChange={e=>setBusinessDisplayName(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    multiline
                    rows={4}
                    fullWidth
                    name="password"
                    label="Head Office Address"
                    id="password"
                    autoComplete="current-password"
                    value={headOfficeAddress}
                    onChange={e=>setHeadOfficeAddress(e.target.value)}
                  />
                  <Grid style={{marginTop: 10}} container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="number"
                        required
                        fullWidth
                        variant='outlined'
                        label='Pincode'
                        value={pincode}
                        onChange={e=>pincodeHandler(e.target.value)}
                      />
                    </Grid>
                    {/* <Grid item xs={4}>
                      <Button startIcon={<Search/>} onClick={pincodeHandler}>Search</Button>
                    </Grid> */}
                  </Grid>
                  <Grid style={{marginTop: 10}} container spacing={2}>
                    <Grid item xs={6}>
                    <TextField
                    variant="outlined"
                    margin="normal"
                    name="password"
                    id="password"
                    label="City"
                    autoComplete="current-password"
                    value={selectedCity}
                    disabled={true}
                  />
                  </Grid>
                  <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="password"
                    id="password"
                    value={selectedState}
                    label="State"
                    autoComplete="current-password"
                    disabled={true}
                  />
                  </Grid>
                  </Grid>
                  <TextField
                    style={{ marginTop : 30 }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Manager Name"
                    id="password"
                    autoComplete="current-password"
                    value={managerName}
                    onChange={e=>setManagerName(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Manager Contact Number"
                    type="number"
                    autoComplete="current-password"
                    value={managerPhone}
                    onChange={e=>setManagerPhone(e.target.value)}
                  />
                </div>
              </Container>
            </CardContent>
        </Card>
       <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          {message}
        </Alert>
      </Snackbar>
      </div>
    );
};
