import '../App.css';
import React from 'react';
import Check from '../images/check.png';
import { CheckCircle } from '@material-ui/icons';

function Wait() {
    return (
        <div className="App" style={{padding:10,marginTop:20}}>
            <img style={{width:256,height:256}} src={Check} alt='check mark'/>
            {/* <CheckCircle fontSize='100' style={{color:'green'}}/> */}
            <h2 style={{marginTop:40}}>Your Application is under review. Please check your Email for further updates.</h2>
        </div>
    )
}

export default Wait;