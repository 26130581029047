const baseURL = `${window.location.origin}/server.signup`;
// const baseURL = 'https://dev.smartfoodqc.com/server.signup'
export const api = {
    "getOtp":`${baseURL}/getOTP`,
    "verifyOtp":`${baseURL}/verifyOTP`,
    "register":`${baseURL}/addAccount`,
    "login":`${baseURL}/coustmerLogin`,
    "saveConfig": `${baseURL}/saveConfiguration`,
    "getConfig": `${baseURL}/getConfiguration`,
    "submitDetails": `${baseURL}/submitForApproval`,
    "appConfig": `${baseURL}/appConfig`
}
