import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'relative',
		maxWidth: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	links: {
		textDecoration: 'none',
	},
	'close-icon': {},
	'close-icon-container': {
		position: 'absolute',
		right: '-1.4rem',
		top: '-1.5rem',
		cursor: 'pointer',
		padding: '0px',
	},
}));

const SupportMessageModal = (props) => {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const { handleClose, open } = props;
	const body = (
		<div className={classes.paper}>
			<div className={classes['close-icon-container']}>
				<IconButton
					onClick={handleClose}
					className={classes['close-icon']}
				>
					<CancelIcon
						style={{
							color: 'black',
						}}
					/>
				</IconButton>
			</div>
			To accelerate setup, just send us a photo / scan of your Menu on{' '}
			<a
				href='https://api.whatsapp.com/send?phone=919958863566&text=I%20need%20some%20help'
				className={classes.links}
			>
				<b>+919958863566 </b> (WhatsApp)
			</a>{' '}
			or{' '}
			<a href='mailto:support@udyogyantra.com' className={classes.links}>
				{' '}
				Email: (support@udyogyantra.com)
			</a>{' '}
			and we will do the needful and take you online within 4 business
			hours.
			{/* Welcome to your own online restaurant ! No Commissions – Direct
			Connect with Customers ! Regards, On-Boarding Team | <a href='https://resoee.com'> resoee.com</a> */}
		</div>
	);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				{body}
			</Modal>
		</div>
	);
};
export default SupportMessageModal;
