import {CardContent, Card, CardHeader, Typography, TextField, Select, InputLabel, FormControl, MenuItem, Switch} from '@material-ui/core';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSharedState } from '../../helpers/sharedState';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
formControl: {
  margin: theme.spacing(2)
  },
}));

export default function IMS() {
  const classes = useStyles();
  const history = useHistory();

  const {
    imsConfig, setImsConfig, cmsConfig, setCmsConfig
  } = useSharedState();

function imsToggleHandler() {
  if (imsConfig.enabled) {
    setImsConfig({
      ...imsConfig,
      "enabled":!imsConfig.enabled,
      "qualityCheck": {
        "image":true,
        "weight":false,
        "temp":false
    },
    "printing": {
        "ipEnabled": false
    }
    });
    // setCmsConfig({
    //   ...cmsConfig,
    //   'stationCamera':false
    // })
  } else {
    setImsConfig({
        ...imsConfig,
        "enabled":!imsConfig.enabled,
    });
  }
}

function qualityCheckHandler(param) {
    let h = {};
    if (param==="weight") h = {weight:!imsConfig.qualityCheck.weight};
    else if (param==="temp") h = {temp:!imsConfig.qualityCheck.temp};
  setImsConfig({
      ...imsConfig,
      "qualityCheck": {
          ...imsConfig.qualityCheck,
          ...h 
      }
  });
}

function printingHandler() {
    setImsConfig({
        ...imsConfig,
        "printing": {
            "ipEnabled": !imsConfig.printing.ipEnabled
        }
    });
}
  
    return(
      <div>
        <Card style={{ padding: "1%" }} elevation={7}>
          <CardHeader style={{textAlign:"center"}} title="Inventory Management" subheader={<Switch checked={imsConfig.enabled} onChange={imsToggleHandler} size="large" color="primary" name="oms"/>}/>
          <CardContent>
              <Container maxWidth="xs">
                <div className={classes.form} noValidate>
                  {/* <Typography>Printing Options: </Typography> */}
                  
                  <Grid style={{marginTop: 10}} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Configuration: </Typography>
                  </Grid>
                    <Grid item xs={12}>
                    <FormControl disabled={!imsConfig.enabled} component="fieldset" className={classes.formControl}>
                        {/* <FormLabel component="legend">Printing Options</FormLabel> */}
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={imsConfig.qualityCheck.image} disabled color="primary" name="Ip" />}
                            label="Image"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={imsConfig.qualityCheck.weight} onChange={()=>qualityCheckHandler("weight")} color="primary" name="Ip" />}
                            label="Weight *"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={imsConfig.qualityCheck.temp} onChange={()=>qualityCheckHandler("temp")} color="primary" name="Ip" />}
                            label="Temperature *"
                        />
                        <FormHelperText>*  Requires UdyogYantra QC Machine</FormHelperText>
                        </FormGroup>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography variant="h6">Printing Options: </Typography>
                  </Grid>
                    <Grid item xs={12}>
                    <FormControl disabled={!imsConfig.enabled} component="fieldset" className={classes.formControl}>
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={imsConfig.printing.ipEnabled} onChange={printingHandler} color="primary" name="Ip" />}
                            label="Enable QR Code Printing"
                        />
                        </FormGroup>
                    </FormControl>
                  </Grid> */}
                  
            
                  </Grid>
                 
                </div>
              </Container>
            </CardContent>
        </Card>
      </div>
    );
};
